@import "main.css";
.rounded-lg-3 { 
max-width: 80% !important; 
}

.first-activity-div p {
color : white !important;
}

.second-activity-div p {
color : white !important;
}

.third-activity-div p {
color : white !important;
}